<template>
  <div
    :data-test-id="dataTest"
    class="relative rounded-md border border-gray-200 transition-all duration-200 ease-in-out"
    :class="{
      'animate-shimmer bg-gradient-shimmer bg-[length:800px_100%]': loading,
      'cursor-pointer hover:bg-opacity-[0.01] hover:shadow-none': navigateTo,
      'p-2': dense,
    }">
    <div
      v-if="!loading"
      class="flex h-full min-h-[180px] flex-col rounded-md bg-gray-100"
      :style="{ minHeight: minh }">
      <div
        v-if="hasToolbarSlot"
        class="relative h-16 border-b border-gray-200">
        <q-toolbar class="text-primary flex-wrap">
          <slot name="toolbar">
            <div class="flex flex-1 flex-row items-center justify-start gap-3">
              <slot name="toolbarleft" />
            </div>
            <div class="flex flex-1 flex-row items-center justify-end gap-3">
              <slot name="toolbarright" />
            </div>
          </slot>
        </q-toolbar>

        <IstHelpbutton
          v-if="showHelpButton"
          class="absolute -bottom-[18px] -right-[18px] z-10"
          :help-id="helpId ?? ''" />
      </div>

      <IstIcon
        v-if="iconName"
        class="w-full pt-2 text-center text-[3em]"
        :name="iconName" />

      <div class="relative flex flex-1 flex-col overflow-visible p-4">
        <slot />
      </div>

      <div
        v-if="showSecondarySectionHeader"
        class="border-y border-black px-4">
        <slot name="body-secondary-toolbar" />
      </div>

      <div
        v-if="showSecondarySection"
        class="relative flex flex-1 flex-col overflow-visible p-4">
        <slot name="body-secondary-section" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, useSlots } from 'vue';
  import { IstIcon } from '@ist/brdk_uigruppe';
  import IstHelpbutton from './IstHelpbutton.vue';
  import { CardTags } from './IstLayoutCard';

  const slots = useSlots();

  interface Props {
    iconName?: string;
    minh?: string;
    arrowColor?: string;
    tags?: CardTags[];
    showActionBtn?: boolean;
    actionBtnLabel?: string;
    loading?: boolean;
    showHelpButton?: boolean;
    helpId?: string;
    showSpinner?: boolean;
    miniCard?: boolean;
    dataTest?: string;
    navigateTo?: string;
    shadow?: boolean;
    dense?: boolean;
    flat?: boolean;
  }

  const {
    iconName,
    minh = '180px',

    loading,
    showHelpButton,
    helpId,
    dataTest = 'ist-layout-card',
    navigateTo,
    dense,
  } = defineProps<Props>();

  const showSecondarySection = computed(() => {
    return slots['body-secondary-section'];
  });
  const showSecondarySectionHeader = computed(() => {
    return slots['body-secondary-toolbar'];
  });

  const hasToolbarSlot = computed(() => {
    return slots['toolbar'] || slots['toolbarleft'] || slots['toolbarright'];
  });
</script>

<style lang="scss">
  .animate-shimmer {
    animation: shimmer 1s linear infinite;
  }

  @keyframes shimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
</style>
