import { ApolloClient, ApolloLink, from, HttpLink } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { GraphQLError, GraphQLFormattedError } from 'graphql';
import { usePregeneratedHashes } from 'graphql-codegen-persisted-query-ids/lib/apollo';
import { v4 } from 'uuid';
import { getToken } from '@/auth/authentification';
import { useSecurityStore } from '@/modules/security/store-index';
import router from '@/navigation/router';
import { useMessageQueueStore } from '@/store/messagequeue';
import { ErrorMappings } from '@/types/errorTypes';
import hashes from '../generated/';
import { cache } from './cache';

const httpLink: ApolloLink = new HttpLink({
  uri: '/graphql',
});

const currentUserLink: ApolloLink = new HttpLink({
  uri: '/environment/graphql',
});

const clientLink: ApolloLink = new HttpLink({
  uri: '/client/graphql',
});

const persistedQueriesLink = createPersistedQueryLink({
  generateHash: usePregeneratedHashes(hashes),
  // NEVER SET TO TRUE IN PRODUCTION
  disable: () => false,
});

const authMiddleWare = new ApolloLink((operation, forward) => {
  const securityStore = useSecurityStore();
  const correlationId = v4();
  const token = getToken();

  const headers = {
    activeEntityId: securityStore.getActiveEntityId,
    'X-CORRELATION-ID': correlationId,
    ...(token && { Authorization: 'Bearer ' + token }),
  };

  operation.setContext({
    headers: headers as Record<string, string>,
  });

  return forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      const versionid = headers.get('x-versionid');
      securityStore.setVersionid(versionid);
    }
    return response;
  });
});


const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  const messageQueue = useMessageQueueStore();

  if (graphQLErrors) {
    graphQLErrors.forEach((error: GraphQLFormattedError) => {
      switch (error.extensions?.code as ErrorMappings) {
        case 'AUTH_NOT_AUTHENTICATED':
          {
            messageQueue.pushMessage('BreakingDataError', 'Bruger er ikke autentificeret korrekt');
          }
          break;
        default:
          // Send all graphql errors to user as a notification. Using the custom human readable text message from the backend.
          messageQueue.pushMessage(
            'NonBreakingDataError',
            'Der skete en fejl ved hentning af data',
          );
          return forward(operation);
      }
    });
  }
  if (networkError) {
    const error: any = networkError;
    const { statusCode } = error;
    let path;

    switch (statusCode) {
      case 401: {
        path = '/invalid';
        break;
      }
      case 403: {
        path = '/invalid';
        break;
      }

      case 404: {
        path = '/invalid';
        break;
      }
      case 405: {
        path = '/login';
        break;
      }
      case 500: {
        messageQueue.pushMessage('NonBreakingDataError', 'Der skete en fejl ved hentning af data');
        path = '/error';

        break;
      }
    }
    path && router.push(path);
    return forward(operation);

    // eslint-disable-next-line no-console
  }
});

// Each split link only handles two conditions. A link to return if truthy and one to return if falsy.
// If we need to hit more endpoints we should consider implementing a 3rd party tool, or write our own implementaion for that.
// For instance: @habx/apollo-multi-endpoint-link
const splitHttp = ApolloLink.split(
  (operation) => {
    return operation.getContext().clientName === 'client';
  },
  clientLink,
  httpLink,
);

const splitLink = ApolloLink.split(
  (operation) => {
    return operation.getContext().clientName === 'userapi';
  },
  currentUserLink,
  splitHttp,
);

export const apolloClient = new ApolloClient({
  link: from([authMiddleWare, errorLink, persistedQueriesLink.concat(splitLink)]),
  cache,
  connectToDevTools: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});
